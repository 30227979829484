import { render, staticRenderFns } from "./CategoryFeaturesBlocksMobile.vue?vue&type=template&id=e6b388bc"
import script from "./CategoryFeaturesBlocksMobile.vue?vue&type=script&lang=ts"
export * from "./CategoryFeaturesBlocksMobile.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@4/components/ui/UiTitle.vue').default,UiDescription: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@4/components/ui/UiDescription.vue').default,UiListItem: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@4/components/ui/UiListItem.vue').default,UiList: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@4/components/ui/UiList.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@4/components/ui/UiButton.vue').default})
